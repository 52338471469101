/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/jquery-lazy@1.7.9/jquery.lazy.min.js
 * - /npm/selectric@1.13.0/public/jquery.selectric.min.js
 * - /npm/ouibounce@0.0.12/build/ouibounce.min.js
 * - /npm/waypoints@4.0.1/lib/jquery.waypoints.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
